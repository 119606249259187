<template>
	<section class="relative flex w-full items-start lg:ml-0">
		<div class="no-scrollbar flex flex-row overflow-scroll">
			<div
				v-for="(image, i) in images"
				:key="image.url"
				class="product-card h-56 w-full flex-[0_0_280px] sm:w-auto lg:h-80 lg:flex-initial xl:flex-1"
			>
				<img
					:src="image.url"
					:alt="image.alt"
					class="h-40 w-[16.56rem] overflow-hidden rounded-lg lg:h-64 lg:w-[26.56rem]"
					loading="lazy"
				/>
				<p class="m-2 text-sm font-light lg:text-base">
					<strong class="mr-1 font-semibold">{{ i + 1 }}.</strong>{{ image.text }}
				</p>
			</div>
		</div>
	</section>
</template>

<script setup lang="ts">
const images = [
	{
		url: 'https://assets.weedys.com/storage/v1/object/public/Images/Curbside-Step-1.jpg',
		text: 'Browse our online shop and add items to your cart.',
		alt: 'Person browsing the weedys.com shop on their mobile phone.',
	},
	{
		url: 'https://assets.weedys.com/storage/v1/object/public/Images/Curbside-Step-2.jpg',
		text: 'Checkout. After you place your order you will receive a confirmation email.',
		alt: 'Person receiving confirmation message after placing their order on weedys.com.',
	},
	{
		url: 'https://assets.weedys.com/storage/v1/object/public/Images/Curbside-Step-3.jpg',
		text: 'Roll up, find a Weedys Express parking spot and check-in with our arrival form!',
		alt: 'Person picking up their weedys.com order curbside.',
	},
];
</script>
